export class AppRoute {
    constructor(
        path,
        element
    ) {
        this.path = path
        this.element = element
    }
}

