import {createSlice} from "@reduxjs/toolkit";


const faqSlice = createSlice({
    name: "student",
    initialState: [
    {
        id: 0,
        question: "Оплатила подписку, но функционал платформы мне все равно недоступен. Что делать?",
        answer: "Обновите страницу и очистите кэш в своем браузере. Если это не помогло, оставьте запрос в службу поддержки - мы все проверим и свяжемся с вами в ближайшее время.",
        active: true,
    },
    {
        id: 1,
        question: "Не получается открыть уроки из раздела «программы» с телефона, с компьютера все работает. Почему?",
        answer: "Раздел программы предусмотрен только для компьютерной версии платформы, так как для проведения уроков и полного использования функционала интерактивных материалов учителю необходимо использовать компьютер или ноутбук. С телефона вы можете использовать остальные разделы платформы, в том числе, личный кабинет и расписание.",
        active: false,
    },
    {
        id: 2,
        question: "Как мне проводить урок по материалам платформы? Как ученик будет видеть материалы урока вместе со мной?",
        answer: "Вы можете использовать функцию трансляции экрана для показа материалов на уроке, для созвана с учеником можно использовать программы zoom, classin, skype, google meet или любые другие приложения, которые для вас окажутся удобными. ",
        active: false,
    },
    {
        id: 3,
        question: "Что такое отдельный и еженедельный урок?",
        answer: "Отдельный урок это стандартный единоразовый урок на конкретную дату, такие уроки мы рекомендуем ставить со студентами, которые не хотят заниматься по фиксированному расписанию (хотят приходить на уроки каждый раз в разное время и разные дни недели). Еженедельный урок это возможность запланировать урок в одно и то же время с одним и тем же студентом в течение месяца. ",
        active: false,
    },
    {
        id: 4,
        question: "Как редактировать информацию в профиле?",
        answer: "Чтобы изменить личную информацию в профиле необходимо нажать на значок красного карандаша возле аватара.",
        active: false,
    },
    {
        id: 5,
        question: "Студент сообщил, что не придет на занятие. Как мне удалить этот урок?",
        answer: "Перейдите в Мое расписание -> Выберите нужный урок и нажмите на него -> Оменить урок. Урок удален!",
        active: false,
    },
    {
        id: 6,
        question: "Я переехала. Как мне изменить часовой пояс?",
        answer: "Чтобы изменить часовой пояс необходимо нажать на значок красного карандаша возле аватара, а затем ввести свою тайм-зону в формате GMT+3, где цифра будет меняться в зависимости от вашего местоположения. Чтобы узнать свою тайм-зону, напишите в поиске браузера «GMT время Москва/GMT время Минск/GMT время Пекин» и так далее. После изменения GMT ваше расписание подстроится под ваш часовой пояс.",
        active: false,
    }
],
    reducers: {
        changeActive: (state, action) => {
            return [...JSON.parse(JSON.stringify(state)).map(el=>{
                el.active = el.id === action.payload;
                return el
            })]
        }
    },

});


export const {changeActive} = faqSlice.actions
export default faqSlice.reducer;