export const color_red_default = "#F04F4F";
export const color_red_active = "#E12F2F";
export const color_red_light = "#F27171";
export const color_red_ultra_light = "#F4BEBE";
export const color_grey_dark = "#B1B1B1";
export const color_grey_light = "#D9D9D9";
export const color_grey_ultra_light = "#F5F5F5";
export const color_white = "#FFFFFF";
export const color_black = "#000000";
export const color_black_light = "#2B2B2B";
export const color_black_ultra_light = "#494949";
export const color_green = "#2ED886";
export const color_green_light = "#EAFBF3";
export const color_blue = "#0085FF";
export const color_blue_light = "#E6F3FF";
export const color_yellow = "#FFC700";
export const color_yellow_light = "#FFF5D4";
export const color_transparent = "transparent";